import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { useHttp } from "./http/httpContext";
import "./styles/global.scss";
import PrivateRoute from "./route/PrivateRoute";
import PublicRoute from "./route/PublicRoute";
import AdminRoute from "./route/AdminRoute";
import Webfont from "webfontloader";
import ScrollToTop from "./components/utilities/ScrollToTop";
import MyToast from "./components/toast/MyToast";
import Loading from "./components/loading/Loading";

// Lazy-loaded components
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const LoginPage = lazy(() => import("./pages/login/LoginPage"));
const Refer = lazy(() => import("./pages/refer/Refer"));
const LocationsList = lazy(() => import("./pages/locations/Index"));
const Households = lazy(() => import("./pages/households/Households"));
const Parcels = lazy(() => import("./pages/parcels/Parcels"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const Scan = lazy(() => import("./pages/scan/Scan"));
const BayView = lazy(() => import("./pages/locations/BayView"));
const Rooms = lazy(() => import("./pages/locations/room/Slug"));
const Collections = lazy(() => import("./pages/collections/Collections"));
const Stock = lazy(() => import("./pages/stock"));
const StockAdd = lazy(() => import("./pages/stock/add"));
const Messages = lazy(() => import("./pages/messages/Messages"));

function App() {
    const { http } = useHttp();

    // Derived state
    const { isLoading } = http;

    // if ('serviceWorker' in navigator) {
    //     window.addEventListener('load', () => {
    //       navigator.serviceWorker.register('/service-worker.js');
    //     });
    // }

    if (!process.env.REACT_APP_BACKEND_URL) {
        return <h1>No Backend URL set.</h1>;
    }

    Webfont.load({
        google: {
            families: ['Inter:300,400,500,600,700,800', 'Material Symbols', 'Material Symbols Rounded']
        }
    })

    return <>
        <MyToast />
        <ScrollToTop />
        <Routes>

            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <Suspense fallback={<Loading />}>
                            <LoginPage />
                        </Suspense>
                    </PublicRoute>
                }
            />

            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <Dashboard />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            {/* <Route path="/register" element={<Register />} /> */}

            <Route path="/refer/*"
                element={
                    <Suspense fallback={<Loading />}>
                        <Refer />
                    </Suspense>
                }
            />

            <Route
                path="/locations"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}><LocationsList /></Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/messages/*"
                element={
                    <AdminRoute>
                        <Suspense fallback={<Loading />}>
                            <Messages />
                        </Suspense>
                    </AdminRoute>
                }
            />

            <Route
                path="/households/*"
                element={
                    <AdminRoute>
                        <Suspense fallback={<Loading />}>
                            <Households />
                        </Suspense>
                    </AdminRoute>
                }
            />

            <Route
                path="/parcels/*"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <Parcels />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/settings/*"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <Settings />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/scan"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <Scan />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/locations/:slug"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <BayView />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/locations/room/:slug"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <Rooms />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/collections/*"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <Collections />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/stock/"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <Stock />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            <Route
                path="/stock/add"
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                            <StockAdd />
                        </Suspense>
                    </PrivateRoute>
                }
            />
        </Routes >
    </>;
}

export default App;
