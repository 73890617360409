import { useEffect } from "react";
import { Toaster, toast } from "sonner";
import { socket } from "src/socket";
import RequiresAdmin from "../permissions/RequiresAdmin";
import { useNavigate } from "react-router-dom";
import newMessageSound from '../../sounds/message-new.mp3';

export default function MyToast() {
    const navigate = useNavigate();
    const newMessageAudio = new Audio(newMessageSound);
    
    useEffect(() => {
        const onNewReferral = (event) => {
            const { message, householdId } = event;
    
            toast('NEW REFERRAL', {
                description: message,
                action: {
                    label: 'View Household',
                    onClick: (e) => {
                        navigate(`households/${householdId}`, {replace: true});
                    },
                },
                cancel: {
                    label: 'Cancel',
                    onClick: (e) => {
                    },
                },
            });
        };
    
        socket.on('newReferral', onNewReferral);
    
        return () => {
            socket.off('newReferral', onNewReferral);
        };
    }, []);

    useEffect(() => {
        const onNewMessage = (event) => {
            toast('NEW MESSAGE', {
                description: event.message
            });
            newMessageAudio.play();
        };

        socket.on('newMessage', onNewMessage);

        return () => {
            socket.off('newMessage', onNewMessage);
        };
    }, []);

    return (
        <RequiresAdmin>
            <Toaster
                position="top-right"
                visibleToasts={9}
                duration={99999999}
                closeButton={true}
            />
        </RequiresAdmin>
    );
}